import { notification, Select } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, FloatingLabel } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../../components/FormFields/CustomDatePicker";
import { Assets } from "../../constants/images";
import {
  BorderCameraIcon,
  ToastCrossIcon,
  ArrowTriangleDown,
} from "../../constants/svg";
import { Register } from "../../services/auth/auth";
import { setAuthData } from "../../store/slices/authSlice";
import {
  constant,
  online_status,
  roles,
  SOFTWARE,
  TECHNOLOGY,
  currentBookingList,
  primaryProductList,
  salonJobType,
  status as salonStatus,
  event_timezone,
} from "../../utils/constants";
import { salonUploadToS3, toastMessage } from "../../utils/helper";
import { GetAuthUserLocalStorage } from "../../services/localStorage/localStorage";
import {
  addUserToFirebaseOrReject,
  signInToFirebaseSync,
} from "../../services/chats/frontend-chats";
import CustomSelect from "../../components/FormFields/CustomSelect";

function BioInfo(props) {
  const [disable, setDisable] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const [file, setFile] = useState({ file: null, file_url: null });
  const [bookingSoftware, setBookingSoftware] = useState([]);
  const [primaryProduct, setPrimaryProduct] = useState([]);
  const [technology, setTechnology] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state?.auth);

  const onSubmit = async (inputFields) => {
    try {
      setDisable(true);

      let salonUploadProfileImageS3Response;
      // if (inputFields.profileImage != "") {
      //   let folder = `${
      //     GetAuthUserLocalStorage()?.salon?.directory_name || "uploads"
      //   }/profile_image`;
      //   salonUploadProfileImageS3Response = await salonUploadToS3(
      //     inputFields.profileImage,
      //     folder,
      //     false,
      //     false,
      //     false,
      //   );
      //   if (!salonUploadProfileImageS3Response.status) {
      //     throw new Error(salonUploadProfileImageS3Response.message);
      //   }
      // }

      dispatch(
        setAuthData({
          ...formData,
          dob: inputFields.dob,
          image: salonUploadProfileImageS3Response
            ? salonUploadProfileImageS3Response.data.location
            : "",
        }),
      );

      const payload = {
        phone:
          formData?.phone && formData?.phone.slice(0, 2) == "+1"
            ? formData.phone
            : "+1" + formData.phone,
        name: formData?.name,
        password: formData?.password,
        password_confirmation: formData?.password_confirmation,
        position_id: formData?.position,
        dob: formData.dob,
        image: salonUploadProfileImageS3Response
          ? salonUploadProfileImageS3Response.data.location
          : "",
        role_id: roles?.admin,
        device_type: constant?.DEVICE_TYPE,
        device_token: constant?.DEVICE_TOKEN,
        email: formData?.email,
        salon_name: formData?.salon_name,
        product_line: primaryProduct,
        date_founded: moment(new Date(formData?.date_founded)).format(
          "YYYY-MM-DD",
        ),
        timezone: formData?.timezone,
        locations: formData?.locations,
        technology,
        software: bookingSoftware,
      };

      const response = await Register(payload);

      const { data, message, status } = response.data;

      if (!status) {
        notification.error({
          description: message,
          placement: "bottomRight",
          className: "createdNotification",
          closeIcon: <ToastCrossIcon />,
        });
        return;
      }
      await signInToFirebaseSync();
      await addUserToFirebaseOrReject({
        email: formData.email,
        id: data && data.user && data.user.id && data.user.id.toString(),
        image: salonUploadProfileImageS3Response
          ? salonUploadProfileImageS3Response.data.location
          : "",
        name: formData?.salon_name,
        online: online_status.offline,
        position: null,
      });

      /*      if (salonUploadProfileImageS3Response) {
        await UpdateFileUploadStorageSizeAvailability(
          salonUploadProfileImageS3Response.data.size
        );
      } */

      toastMessage("success", "Please verify your email!");
      navigate("/verify-otp");
    } catch (e) {
      toastMessage("error", e.message);
      setDisable(false);
    } finally {
      setDisable(false);
    }
  };

  const goBackFunc = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (formData?.dob) {
      setValue("dob", formData?.dob);
    }
  }, [formData]);

  useEffect(() => {
    if (formData.name == "") navigate("/setup");
  }, []);

  return (
    <div className="authBox professionalBio">
      <div className="authHeaderText">
        <h1>Profile Details</h1>
        {/* <p>Add your photo or logo and select your preferences.</p> */}
        <p>Lastly, select the products and tech you use.</p>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {/* <Controller
          control={control}
          name="profileImage"
          defaultValue={""}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="profile-img-wrapper pb-4">
              <label className="profile-img-wrapper" htmlFor="profile">
                <input
                  className="d-none"
                  onChange={(e) => {
                    onChange(e.target.files[0]);
                    setFile({
                      file: e.target.files[0],
                      file_url: URL.createObjectURL(e.target.files[0]),
                    });
                  }}
                  type="file"
                  id="profile"
                  name="profile"
                  accept="image/*"
                />
                <div className="myProfile__img">
                  <img
                    src={file.file_url ? file.file_url : Assets.placeHolderIcon}
                    alt=""
                  />
                </div>
                <span className="cameraIcon">
                  <BorderCameraIcon />
                </span>
              </label>
            </div>
          )}
        /> */}

        <div className="all_select bio">
          <div className="select_box_new mb-20">
            <div className="select_option_new">
              <Select
                showSearch
                value={primaryProduct}
                mode="multiple"
                size="middle"
                placeholder="Product Line(s)"
                style={{ width: "100%", height: "auto", minHeight: "58px" }}
                options={primaryProductList.map((el) => ({
                  value: el,
                  label: el,
                }))}
                popupClassName="new_select_class"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<ArrowTriangleDown />}
                onChange={(e) => {
                  setPrimaryProduct(e);
                }}
              />
            </div>
          </div>
          <div className="select_box_new mb-20">
            <div className="select_option_new">
              <Select
                showSearch
                value={bookingSoftware}
                mode="multiple"
                size="middle"
                placeholder="Booking Software"
                style={{ width: "100%", height: "auto", minHeight: "58px" }}
                options={SOFTWARE.map((el) => ({ label: el, value: el }))}
                popupClassName="new_select_class"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<ArrowTriangleDown />}
                onChange={(e) => {
                  setBookingSoftware(e);
                }}
              />
            </div>
          </div>
          <div className="select_box_new mb-20">
            <div className="select_option_new">
              <Select
                showSearch
                value={technology}
                mode="multiple"
                size="middle"
                placeholder="Other Technology"
                style={{ width: "100%", height: "auto", minHeight: "58px" }}
                options={TECHNOLOGY.map((el) => ({ label: el, value: el }))}
                popupClassName="new_select_class"
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                suffixIcon={<ArrowTriangleDown />}
                onChange={(e) => {
                  setTechnology(e);
                }}
              />
            </div>
          </div>
        </div>

        <Row className="mt-4">
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              className={"w-100 btn-submit btn-gray"}
              onClick={goBackFunc}
            >
              Back
            </Button>
          </Col>
          <Col lg={6} md={6} sm={6} xs={6}>
            <Button
              type={"submit"}
              className={"w-100 btn-submit"}
              disabled={!isValid || disable}
            >
              {!disable ? "Finish" : <div className="load black" />}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default BioInfo;
